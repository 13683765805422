import DataTable from 'datatables.net-dt';
import {initInput, setEquleHeight, scroll2element} from './functions';

var vw = window.innerWidth;
var vh = window.innerHeight;
var sw = screen.width;
var sh = screen.height;
var pageScroll = window.pageYOffset || document.documentElement.scrollTop;
$(document).ready(function () {

    function sendForm($el) {
        var $form = $el,
            $btn = $form.find('button'),
            fd = new FormData($form[0]);

        if ($btn.hasClass('is-loading')) return;

        $.ajax({
            url: $form.attr('action'),
            type: $form.attr('method'),
            data: fd,
            processData: false,
            contentType: false,
            dataType: 'json',
            // dataType: 'html',
            beforeSend: function () {
                hideErrorFields($form);
                showBtnLoading($btn);
            },
            success: function (data) {
                setTimeout(function () {
                    hideBtnLoading($btn);
                    initInput();

                    if (data.status) {
                        if (data.messages.length) {
                            showPopupMessage(data.messages);
                            if ($form.data('reach-goal') !== '') {
                                ym(37059350,'reachGoal',$form.data('reach-goal'));
                            }
                            $form[0].reset();
                        }
                    } else {
                        if (data.messages.length) {
                            showPopupMessage(data.messages);
                        }
                        showErrorFields($form, data.errors);

                        if(data.messages.includes("капч")){
                            grecaptcha.reset();
                        } else if($form.find('g-recaptcha-response')){
                            grecaptcha.reset();
                        }
                    }
                }, 1000);
            },
            error: function (data) {
                // console.log('form error:', data);
                setTimeout(function () {
                    hideErrorFields($form);
                    hideBtnLoading($btn);
                }, 1000);
            }
        });

        function showErrorFields($form, errors) {
            $.each(errors, function (i, val) {
                $el = $form.find("[name='" + i + "']");
                if ($el.length) $el.addClass('is-error');
            });
        }
        function hideErrorFields($form) {
            $form.find('.is-error').removeClass('is-error');
        }

        function showBtnLoading($btn) {
            $btn.addClass('is-loading');
            $btn.attr('disabled', true);
        }
        function hideBtnLoading($btn) {
            $btn.removeClass('is-loading');
            $btn.attr('disabled', false);
        }
    }

    /* PARAMS */

    $(window).resize(function () {
        vw = window.innerWidth;
        vh = window.innerHeight;
        sw = screen.width;
        sh = screen.height;
    });

    $(document).scroll(function () {
        pageScroll = window.pageYOffset || document.documentElement.scrollTop;
    });

    initInput();
    document.querySelectorAll('img[data-src]').forEach((img) => {
        img.setAttribute('src', img.getAttribute('data-src'));
        img.onload = function() {
            img.removeAttribute('data-src');
        };
    });

    /* ACTIONS */

    // плавный скрол до элемента и отключение действий для ссылок "#"
    $('[href^="#"]').click(function (e) {
        e.preventDefault();
        if ($(this).attr('href').length > 1) {
            scroll2element(
                $($(this).attr('href')),
                1000,
                165,
                false
            );
        }
    });

    if (sw > 580) {
        var totalWidthColumn = 0;
        var countColumn = 0;
        var widthTable = 1230; // минус ширина пустой ячейки в левом верхнем углу
        $('.section__items-table-root table th.width').each(function () {
            totalWidthColumn += $(this).width();
            countColumn++;
        });
        if (totalWidthColumn < widthTable) {
            var width = (widthTable / countColumn) + 'px';
            $('.section__items-table-root table th.width').css({
                minWidth: width
            });
        }
    }

    setTimeout(function () {
        var table = new DataTable('#section__items-table-root__table', {
            scrollX: false,
            scrollCollapse: true,
            paging: false,
            searching: false,
            sort: false,
            info: false,
            fixedColumns: {
                leftColumns: 1
            },
        });
    }, 2000);

    $('.section__items-table-root table .price').hover(function () {
        var $tablePrices = $('#section__items-table-root__table');
        var rightTable = $tablePrices.offset().left + $tablePrices.width();
        var bottomTable = $tablePrices.offset().top + $tablePrices.height();
        var $poppupCard = $(this).find('.section__items-table-popup');
        if ($poppupCard.offset().left + $poppupCard.width() > rightTable) {
            $poppupCard.css({
                left: 'unset',
                right: '100%'
            });
        }
        if ($poppupCard.offset().top + $poppupCard.height() > bottomTable) {
            $poppupCard.css({
                top: 'unset',
                bottom: '0'
            });
        }
    });

    $('.header__msearch').click(function () {
        $('.header__search').slideToggle(500);
    });

    // ajax forms
    $(document).on('submit', '[data-form-ajax]', function (e) {
        e.preventDefault();
        sendForm($(this));
    });

    // всплывающие формы
    $('[data-popup-form]').click(function (e) {
        e.preventDefault();
        var form = $(this).data('popup-form');

        $.colorbox({
            href: '/ajax/form/' + form + '-form.php',
            className: 'colorbox-form',
            maxWidth: '100%',
            maxHeight: '100%',
            opacity: false,
        });
    });

    $('[data-oneclick]').click(function (e) {
        e.preventDefault();
        let title = $(this).data('title');

        $.colorbox({
            href: '/ajax/form/oneclick-form.php',
            className: 'colorbox-form',
            data: {
                title
            },
            maxWidth: '100%',
            maxHeight: '100%',
            opacity: false,
            onComplete: function () {
                initInput();
            }
        });
    });


    $('.burger').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.header-menu__mobile').toggleClass('active');
        $('body').toggleClass('overflow');
    });

    $('.header-menu__mobile__dropdown').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('active');
        $('.header-menu__mobile__catalog').slideToggle(300);
    });

    $('.is-parent .icon').on('click', function (e) {
        /* if ($('.is-parent').hasClass('.active')) {
            $(this) e.preventDefault();
        } else { */
            $(this).parent('.is-parent').toggleClass('active');
            $(this).parent('.is-parent').children('ul').slideToggle(300);
        /* } */
    });

    $(function() {
        var reCaptchaWidth = 330;
        var containerWidth = $('.container-capcha').width();
        if(reCaptchaWidth > containerWidth) {
            var reCaptchaScale = containerWidth / reCaptchaWidth;
            $('.google-recaptcha').css({
                'transform':'scale('+reCaptchaScale+')',
                'transform-origin':'left top'
            });
        }
    })

    $('.tab__content-item').each(function( index ) {
        if (index == 0) {
            $(this).addClass('active');
        }
    })

    $('.tab__item').on('click', function () {
        let tabID = $(this).data('id'),
            tabHeight = $('.tab__content-item.active').offset().top - 55;
        $('html, body').animate({
            scrollTop: tabHeight
        }, 500);
        console.log(tabHeight);
        $('.tab__item').removeClass('active');
        $(this).addClass('active');
        $('.tab__content-item.active').removeClass('active');
        $('#' + tabID).addClass('active');
        if (tabID == 'complect' && !$('#complect .section__base-items').hasClass('slick-initialized')) {
            if ($('#complect .section__base-item').length > 4) {
                $('#complect .section__base-items').slick({
                    infinite: true,
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1
                            }
                        }
                    ]
                })
                    .on('setPosition', function (event, slick) {
                        slick.$slides.css('height', slick.$slideTrack.height() + 'px');
                    });                
            }
        }

    });

    // colorbox
    $('.colorbox').colorbox({
        maxWidth: '100%',
        maxHeight: '100%',
        opacity: false
    });


    $('.reviews__more').click(function () {
        $(this).parent().find('.reviews__tokens').remove();
        $(this).parent().find('.reviews__text--end').css({
            display: 'inline'
        });
        $(this).remove();
    });

    // colorbox buttons svg
    (function () {
        $(document).bind('cbox_complete', function () {
            initInput();
            $("#cboxPrevious").html('<svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></svg>');
            $("#cboxNext").html('<svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></svg>');
        });

        $(document).bind('cbox_load', function () {
            $("#cboxClose").html('<button class="modal__close"></button>');
        });
    })();


    // slick
    $('.slider').slick({
        arrows: true,
        prevArrow: '<i class="slider__arrow slider__arrow--prev"><svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></svg></i>',
        nextArrow: '<i class="slider__arrow slider__arrow--next"><svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></svg></i>',

        dots: true,
        dotsClass: 'slider__dots',
        customPaging: function (slider, i) {
            return '';
        },

        autoplay: false,
        autoplaySpeed: 3000,

        infinite: false,
        adaptiveHeight: true,

        slidesToShow: 1,
        slidesToScroll: 1,

        mobileFirst: true,
        responsive: [{
            breakpoint: 1219,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        }]
    });
    setTimeout(setEquleHeight($('.section__item-text')), 500);
    $('.section__item-info-block').height('140px');

    // fixed header
    var vw = window.innerWidth;

    if (vw > 767) {
        const headerHeight = $('.header').outerHeight();
        var headerBottom = $('.header__bottom');

        $(window).scroll(function() {
            if ($(this).scrollTop() > headerHeight) {
                headerBottom.css({
                    'top': 0 ,
                }).addClass('header_fixed');
            } else {
                headerBottom.css({
                   'top': '-'+headerHeight+'px'
                }).removeClass('header_fixed');
            }
        });
    } else {
        const headerHeight = $('.header__middle').outerHeight();
        var headerMobile = $('.mobile-fix');

        $(window).scroll(function() {
            if ($(this).scrollTop() > headerHeight) {
                headerMobile.css({
                    'top': 0 ,
                }).addClass('header_fixed');
            } else {
                headerMobile.removeClass('header_fixed');
            }
        });

    }
    $('.detail-info .tab__item:eq(0)').addClass('active');
    $('.tab__content .tab__content-item:eq(0)').addClass('active');

    $('.tab__list .tab__item:eq(0)').addClass('active');
    $('.tab__list .tab__content-item:eq(0)').addClass('active');

});


$(function scrollTop() {
    var $upButton = $('.up');
    $(window).scroll(function() {
        if ($(this).scrollTop() > 400) {
            $upButton.fadeIn();
        } else {
            $upButton.fadeOut();
        }
    });
    $upButton.click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
    });
})
