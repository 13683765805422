import {Fancybox} from "@fancyapps/ui";

document.addEventListener('submit', function (btn) {
    let form = btn.target.closest('form');
    const button = form.querySelector('button');
    if (typeof form.dataset.sendAjax !== 'undefined') {
        btn.preventDefault();
        button.setAttribute('disabled', true);

        let formData = new FormData(form);
        const action = form.attributes.action.value;
        form.querySelectorAll('[name]').forEach(field => {
            field.classList.remove('is-error');
        });

        if (!form.classList.contains('catalog-adv-form')) {

            fetch(action, {
                method: 'POST',
                body: formData
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.status) {
                        Fancybox.close();
                        document.querySelectorAll('.fancybox__container').forEach(el => el.remove());
                        Fancybox.show([{
                            type: 'inline',
                            html: '<div class="form-answer">' + data.messages + '</div>'
                        }]);
                        form.reset();
                    } else {
                        for (let error in data.errors) {
                            form.querySelector(`[name="${error}"]`).classList.add('is-error');
                        }
                        var mess = document.querySelector('.form-answer');
                        if (mess) {
                            mess.innerHTML = data.messages;
                        } else if (data.messages) {
                            Fancybox.close();
                            document.querySelectorAll('.fancybox__container').forEach(el => el.remove());
                            Fancybox.show([{
                                type: 'inline',
                                html: '<div class="form-answer">' + data.messages + '</div>'
                            }]);
                            form.reset();
                        }

                    }
                    button.removeAttribute('disabled');
                });
        } else {

            grecaptcha.ready(function () {
                grecaptcha.execute('6LcN6j4pAAAAAAN379EoJBDZ2YhDZaYaSeIviuBo', { action: 'contact' }).then(function (token) {
                    formData.append('recaptcha_response', token);

                    fetch(action, {
                        method: 'POST',
                        body: formData
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                             if (data.status) {
                                Fancybox.close();
                                document.querySelectorAll('.fancybox__container').forEach(el => el.remove());
                                Fancybox.show([{
                                    type: 'inline',
                                    html: '<div class="form-answer">' + data.messages + '</div>'
                                }]);
                                form.reset();
                            } else {
                                for (let error in data.errors) {
                                    form.querySelector(`[name="${error}"]`).classList.add('is-error');
                                }
                                var mess = document.querySelector('.form-answer');
                                if (mess) {
                                    mess.innerHTML = data.messages;
                                } else if (data.messages) {
                                    Fancybox.close();
                                    document.querySelectorAll('.fancybox__container').forEach(el => el.remove());
                                    Fancybox.show([{
                                        type: 'inline',
                                        html: '<div class="form-answer">' + data.messages + '</div>'
                                    }]);
                                    form.reset();
                                }

                            }
                            button.removeAttribute('disabled');
                        });
                });
            });
        }
    }
});
