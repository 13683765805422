import '../../bower_components/slick-carousel/slick/slick.min.js';
import colorbox from '../../bower_components/jquery-colorbox/jquery.colorbox.js';
import '../../bower_components/jquery.form-styler/dist/jquery.formstyler.min.js';

import {Fancybox} from "@fancyapps/ui";

import './header-menu';
import './header-mobile-menu';
import './ymaps';
import './popups';
import './site';
import './modules/SendAjax';
import './modules/Main';

import {initInput} from "./functions";

document.addEventListener('DOMContentLoaded', function () {
    Fancybox.bind("[data-fancybox]", {
        dragToClose: false,
        on: {
            done: (fancybox, slide) => {
                initInput();
            },
            init: () => {
                Fancybox.close();
            }
        }
    });
});