document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('[data-toggle-menu]').forEach(el => {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            this.querySelector('.header__burger-switcher').classList.toggle('is-open');
            document.querySelector('.header-menu-catalog').classList.toggle('is-open');
        });
    });

    setMenuPositionTop();
    headerFixed();

    $(window).scroll(function (event) {
        headerFixed();
    });


    // Фиксация шапки
    function headerFixed() {
        let st = $(window).scrollTop();
        // if (st > (($('.shop-link').outerHeight() ? $('.shop-link').outerHeight() : 0) + $('.header-top').outerHeight())) {
        if (st > 90) {
            $('header').addClass('fixed');
            $('.header').addClass('fixed');
            // $('.main-wrapper').addClass('fixed');
            setMenuPositionTop();
        } else if (st < 50) {
            $('header').removeClass('fixed');
            $('.header').removeClass('fixed');
            setMenuPositionTop();
        }
    }

    // Позиционирование выпадающего меню каталога
    function setMenuPositionTop() {
        setTimeout(function () {
            let windowHeight = $(window).outerHeight();
            let header = $('header').outerHeight();
            let headerMiddle = $('.header').outerHeight();
            let headerMenu = $('.header-menu').outerHeight();
            let headerTop = $('.header-top').outerHeight();
            let shopLink = ($('.shop-link').outerHeight() ? $('.shop-link').outerHeight() : 0);

            $('header').css({
                top: '-' + (shopLink + headerTop) + 'px'
            });
            if ($('header').hasClass('fixed')) {

                $('.header-menu-catalog').css({
                    height: (windowHeight - (headerMiddle + headerMenu)) + 'px',
                    top: (headerMiddle + headerMenu) + 'px'
                });
            } else {
                // $('header').css({
                //     top: '0px'
                // });
                $('.header-menu-catalog').css({
                    height: (windowHeight - (header)) + 'px',
                    top: header + 'px'
                });
            }
        }, 500);
    }

});