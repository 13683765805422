document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('[data-menu-mobile-toggle]').forEach(el => {
        el.addEventListener('click', function (e) {
            this.closest('.header-menu-mobile-catalog__section').querySelector('ul').classList.toggle('is-open');
            this.classList.toggle('is-open');
        });
    });
    document.querySelector('[data-toggle-mobile-menu]').addEventListener('click', function () {
        this.querySelector('.header__burger-switcher').classList.toggle('is-open');
        document.querySelector('.header-menu-mobile-catalog').classList.toggle('is-open');
    });

    setMenuMobilePosition();

    $(window).scroll(function (event) {
        setMenuMobilePosition();
    });

    function setMenuMobilePosition() {
        let shopLink = $('.shop-link').outerHeight();
        let header = $('.header').outerHeight();
        const mobileMenu = $('.header-menu-mobile-catalog');
        if ($('header').hasClass('fixed')) {
            mobileMenu.css({
                top: (header) + 'px'
            });
        } else {
            mobileMenu.css({
                top: (header + shopLink) + 'px'
            });
        }
    }
});