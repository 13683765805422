import {Swiper} from 'swiper';
import { Autoplay, Navigation } from 'swiper/modules';

document.addEventListener('DOMContentLoaded', function () {
    Swiper.use([Navigation, Autoplay]);
    new Swiper(".main-slider", {
        // autoplay: {
        //     delay: 5000,
        // },
        speed: 800,
        loop: true,
        navigation: {
            nextEl: ".main-slider__nav--next",
            prevEl: ".main-slider__nav--prev",
        },
    });
});